import request from "@/redux/services/request";
import requestToken from "@/redux/services/requestToken";
import { config } from "@/utils/config";

export const getReservationData = (queryString = "", cancelToken) =>
  request({
    url: `/${config.api_ver}/checkout/checkout_confirmation?booking_id=${queryString}`,
    method: "GET",
    cancelToken
    // dev3: true,
  });

export const cancelReservationData = (queryString = "") =>
  requestToken({
    url: `/${config.api_ver}/trips/cancel_trip?booking_id=${queryString}`,
    method: "GET",
    // dev3: true,
  });

export const getOTP = (queryString = "", recaptcha) =>
  request({
      url: `/${config.api_ver}/send_otp`,
      method: "POST",
      data: {
        id: queryString,
        'g-recaptcha-response': recaptcha
      }
    });

export const verifyOTP = (userID, otp) =>
  request({
      url: `/${config.api_ver}/verify_otp/${userID}/${otp}?token=generate`,
      method: "GET",
      dev3: true,
    });

export const coniformCancellation = (bookingID) =>
  request({
      url: `/${config.api_ver}/trips/detail/${bookingID}?guest=true`,
      method: "GET",
    });

export const cancelReservationDataWithOtp = (id) =>
    requestToken({
      url: `/${config.api_ver}/trips/cancel_trip?${id}`,
      method: "GET",
    });
