import { getActivityCategories } from "@/redux/services/global";
import { EntityRequestProcessingStatus } from "@/utils/entityRequestAdapter";
import { currentPositionPromise } from "@/utils/currentPosition";
import { deNormalizeLocation, splitByToken } from "@/utils/locationNormalizers";
import { useEffect, createContext, useState, useRef } from "react";
import { DestinationModel } from "../../Destination";
import ActivityInvariantException from "../../exceptions/ActivityInvariantException";
import useUserLocation from "../../useUserLocation";
import { useOmnisearchState } from "../OmnisearchState";
import useValidationInvariants from "./useValidationInvariants";

export const InterestsContext = createContext({});

const Interests = ({ children }) => {
    const ctaRef = useRef(null);
    const {
        destination: destinationState,
        interests: interestsState
    } = useOmnisearchState();

    const [, setSelectedInterests] = interestsState;
    const [, setDestinationModelState] = destinationState;

    const { getAddress, getName, getLocationType } = useUserLocation();

    const [interests, setInterestsState] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [destinationProcessingStatus, setDestinationProcessingStatus] = useState(EntityRequestProcessingStatus.UNINITIALIZED);

    const {
        validateInvariants,
        isLocationInvalid,
        selectIntersected,
        activityValidationError,
        setActivityValidationError
    } = useValidationInvariants();

    const getUserLocationDestinationEntity = async () => {
        let destinationModel = new DestinationModel();

        try {
            const response = await currentPositionPromise;

            const {
                city_center: cityCenter
            } = response.data.result;

            const [name, address, type] = [
                getName,
                getAddress,
                getLocationType
            ].map(getterHelper => getterHelper(response.data?.result));

            destinationModel = new DestinationModel({
                id: '',
                name,
                address,
                type,
                slug: (name ?? '').toLocaleLowerCase(),
                location: {
                    lat: cityCenter.lat,
                    lon: cityCenter.lon
                },
            });
        } finally {
            return destinationModel;
        }
    };

    const getSelectedLocation = destinationModel =>
        splitByToken(deNormalizeLocation(destinationModel.searchTerm));

    const fetchActivities = async destinationModel => {
        const [city, state, country] = getSelectedLocation(destinationModel);

        if (isLocationInvalid([city, state, country])) {
            return;
        }

        let activitiesResponseModel = [];
        setLoading(true);

        try {
            const { data: activitiesModel } = await getActivityCategories({
                city,
                state,
                country
            });

            activitiesResponseModel = activitiesModel;

            validateInvariants(activitiesModel, destinationModel);
        } catch (error) {
            if (error instanceof ActivityInvariantException) {
                setActivityValidationError(error);
            }
        } finally {
            // Is business team is completely crazy uncomment this
            //if (activitiesResponseModel.length > 0) {
            setInterestsState(activitiesResponseModel);
            //}

            setSelectedInterests(selectIntersected(activitiesResponseModel));

            // This will not work, but our business team motherfucking STUPID fuckers
            // if (isResponseEmpty(activitiesResponseModel) && !isRecursiveCall) {
            //     getUserLocationDestinationEntity()
            //         .then(userLocationModel => fetchActivities(userLocationModel, true));
            // }
            setLoading(false);

            return activitiesResponseModel;
        }
    };

    const resetValidationError = () => {
        setActivityValidationError(null);
    };

    const isComponentMountedRef = useRef(false);

    const fetchActivitiesFromUserLocationRef = useRef(async () => {
        setDestinationProcessingStatus(EntityRequestProcessingStatus.IN_PROGRESS);

        try {
            const userLocationEntity = await getUserLocationDestinationEntity();
            const activities = await fetchActivities(userLocationEntity);
            
            // bug here
            // if (isComponentMountedRef.current &&
            //     userLocationEntity &&
            //     (activities?.length > 0)) {
            //     // This will unlock search button
            //     setDestinationModelState(userLocationEntity);
            // }

            setDestinationProcessingStatus(EntityRequestProcessingStatus.RESOLVED_SUCCESS);
        }catch {
            setDestinationProcessingStatus(EntityRequestProcessingStatus.RESOLVED_ERROR);
        }
    });

    useEffect(() => {
        isComponentMountedRef.current = true;

        fetchActivitiesFromUserLocationRef.current();

        return () => {
            isComponentMountedRef.current = false;
        };
    }, []);

    return (
        <InterestsContext.Provider
            value={{
                data: interests,
                fetchActivities,
                isLoading,
                destinationProcessingStatus,
                validationError: activityValidationError,
                resetValidationError,
                getSelectedLocation,
                ctaRef,
                fetchActivitiesFromUserLocation: fetchActivitiesFromUserLocationRef.current
            }}
        >
            {children}
        </InterestsContext.Provider>
    );
};

export default Interests;
